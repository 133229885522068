import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Showroom = () => {
  return (
    <section className="w-full flex justify-center py-20">
      <div className="w-full md:w-3/4 lg:w-1/2 flex justify-center flex-col">
        <h2 className="text-center py-20">VISIT OUR BOUTIQUE SHOWROOM</h2>

        <StaticImage
          src="../../images/preowned/gray-and-sons-showroom-bal-harbour-miami-beach.png"
          alt="Gray and Sons Jewelers in Bal Harbour Miami Beach Showroom"
          aria-label="Gray and Sons Jewelers Showroom"
        />
        <div className="p-5 lg:px-0 w-full flex justify-center flex-col ">
          <div className="w-full flex justify-center">
            <div className=" w-full md:w-3/4 lg:w-1/2 flex justify-center flex-col">
              Visit us in person at our gorgeous showroom and see ALL of our products up close.
              Located at 9595 Harding Ave, Surfside (Miami Beach), Fl 33154. Located across from Bal
              Harbor Shops. Chat with our wonderful sales team and sign up for our monthly catalog
              on our website <a href="/">www.grayandsons.com</a>.
              <br />
              <br />
              <div className="text-center font-bold">
                <nobr> Call us Toll Free: (800) 918-2608</nobr>| <nobr>Fax: (305) 865-9666</nobr> |{' '}
                <nobr>Email: info@grayandsons.com</nobr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Showroom
