import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/LayoutPreowned'
import ProductGrid from '../../components/products/ProductGrid'

// markup
const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query PreownedIndexQuery {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { eq: "Ballon Bleu" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Pre-Owned Cartier Watches | Gray and Sons Jewelers'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Used watches with over 40 years of experience in the business'
      }
      canonical={'/preowned/'}
    >
      <h1>Used Pre-Owned Cartier Watches</h1>
      <ProductGrid products={data.products.nodes} />
    </Layout>
  )
}

export default IndexPage
