import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Icons from '../components/Icons'
import Header from './layout/Header'
import FooterAlt from './layout/FooterAlt'
import PopUpsAndChat from './layout/PopUpsAndChat'
import '../styles/main.scss'
import '../styles/preowned.scss'
import Showroom from './preowned/Showroom'
import Seo from './Seo'

const LayoutPreOwned = ({
  children,
  breadcrumbs,
  className,
  shop,
  siteTitle,
  canonical,
  description,
}) => {
  const [navbarOpen, setNavbarOpen] = useState(null)
  return (
    <>
      <Icons />
      <Header
        breadcrumbs={breadcrumbs}
        canonical={canonical}
        shop={shop}
        title={siteTitle}
        navbarOpen={navbarOpen}
        setNavbarOpen={setNavbarOpen}
      />
      <Seo title={siteTitle} canonical={canonical} description={description} />
      <main
        className={
          (navbarOpen
            ? 'hidden lg:block'
            : className || ' mx-auto max-w-[1366px] w-10/12 leading-loose overflow-x-hidden') +
          ' preowned'
        }
      >
        {children}
      </main>

      <Showroom />
      <FooterAlt className={navbarOpen ? 'hidden lg:block' : ''} />

      <PopUpsAndChat navbarOpen={navbarOpen} />
    </>
  )
}

LayoutPreOwned.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  className: PropTypes.string,
  canonical: PropTypes.string,
  siteTitle: PropTypes.string,
  shop: PropTypes.bool,
  description: PropTypes.string,
}

LayoutPreOwned.defaultProps = {
  children: null,
  className: null,
  breadcrumbs: false,
  canonical: null,
  shop: false,
  siteTitle: null,
  description: null,
}

export default LayoutPreOwned
